import styled from 'styled-components';

export const SwiperContainer = styled.div`
    .swiper-container {
        width: 100%;
        height: 100%;
        padding-bottom:40px;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        width: clamp(250px, 60%, 350px);
        text-align:left;
    }

    &&& .swiper-pagination-bullet-active{
        background: black;
    }
`;

export const SlideContainer = styled.div`
    text-align: left; 
    height:100%;
`; 

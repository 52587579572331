//TODO: implement proptypes
// import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Pagination
} from 'swiper/core';

import CustomCard from '../../global/CustomCard';
import React from 'react';
import { SwiperContainer } from './CardCarouselSection.styles';

SwiperCore.use([Pagination]);

const CardCarouselSection = (props) => (
  <SwiperContainer>
    <Swiper slidesPerView={'auto'} centeredSlides={true} spaceBetween={30} pagination={{"clickable": true}} className="mySwiper">
      { props.cards.map((card,i) => {
        return(
          <SwiperSlide className="swiper-custom-slides" key={i}>
              <CustomCard 
                key={i}
                cardsPerRow={props.cardsPerRow} 
                title={card.title} 
                description={card.description} 
                link={card.link}
                imageSrc={card.imageSrc}
                linkText={card.linkText}
              />
          </SwiperSlide>
        )
      })}
    </Swiper>
  </SwiperContainer>
);

CardCarouselSection.propTypes = {
  // bla: PropTypes.string,
};

CardCarouselSection.defaultProps = {
  // bla: 'test',
};

export default CardCarouselSection;



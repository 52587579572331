import { CardContent, CardMedia } from "@material-ui/core"
import styled, { css } from 'styled-components';

import Button from "../Button"

export const StyledCardMedia = styled(CardMedia)`
    height:200px;
`;

export const StyledWrapper = styled.a`
    &:hover{
        text-decoration: none;
    }
`;

export const StyledCardContent = styled(CardContent)`
    padding:25px;
    flex:1;
    display:flex;
    flex-flow: column;
    justify-content:space-between;
    &:last-child{
        padding-bottom: 25px;
    }
`;

export const Title = styled.h4`
    font-weight: bold;
    font-size: var(--fontSize-2);
`;

export const Description = styled.p`  
    min-height: 90px;
    max-height:200px;
`;

export const StyledButton = styled(Button)`
    margin-top: 20px;

`;

export const StyledCard = styled.div`
    flex:1;
    height:100%;
    transition: all .2s;
    transform: scale(1.00);
    :hover{
        transform: scale(1.00);
    }
    :active{
        transform: scale(1.00);
    }
    /* .MuiPaper-elevation1{
        box-shadow: 0 2px 8px 0 rgba(207,198,198,0.5);
    } */
    .MuiPaper-root{
        height:100%;
        display:flex;
        flex-flow:column;
    }
    
    ${props => props.isLinked && 
        css`
        :hover{
            transform: scale(1.05);
        }
        :active{
            transform: scale(.95);
        }
        `
    }
`
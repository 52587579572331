import { Description, StyledButton, StyledCard, StyledCardContent, StyledCardMedia, StyledWrapper, Title } from "./CustomCard.styles"

import { Card } from "@material-ui/core"
import PropTypes from 'prop-types';
import React from 'react';
import parse from "html-react-parser"

const CustomCard = (props) => (
  <StyledCard isLinked={props.link}>
    { props.link ?
      // If link exists, wrap the whole card in it
      <StyledWrapper href={props.link}>
        <Card>
            <StyledCardMedia
              image={props.imageSrc}
              title={props.title}
              />
            <StyledCardContent>
              <div>
                <Title>{props.title}</Title>
                {props.description && <Description>{parse(props.description)}</Description>}
              </div>
              {props.linkText && <StyledButton type={props.buttonType ? props.buttonType : "tertiary"}>{props.linkText}</StyledButton>}
            </StyledCardContent>
        </Card>
      </StyledWrapper>
      :
      // If no link exists, don't wrap the card in <a>
      <Card>
          <StyledCardMedia
            image={props.imageSrc}
            title={props.title}
            />
          <StyledCardContent>
            <Title>{props.title}</Title>
            {props.description && <Description>{parse(props.description)}</Description>}
          </StyledCardContent>
      </Card>
    }
  </StyledCard>
);

CustomCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  imageSrc: PropTypes.string,
};

CustomCard.defaultProps = {
  title: "Card title",
  description: null,
  link: null,
  linkText: null,
  imageSrc: "https://via.placeholder.com/350x200",
};

export default CustomCard;
